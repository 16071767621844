import CustomNextLink from '@/components/CustomNextLink';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { Button } from '../../components/@next/Button/Button';
import routes from '../../routes/routes';
import styles from './errorPage.module.scss';
export type ErrorPageProps = {
  msg?: React.ReactNode;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ msg = 'Something went wrong' }) => {
  return (
    <main className={`container flex-center error-page-wrapper ${styles['error-page-wrapper']}`}>
      <img src="/images/errorPage-oops-img.png" alt="error image" />
      <p>{msg}</p>
      <CustomNextLink to={routes.home}>
        <Button>
          <ArrowLeftOutlined /> Back to home
        </Button>
      </CustomNextLink>
    </main>
  );
};
