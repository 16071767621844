import { ErrorPage } from '../containers/errorPage';
import NextApp from '../NextApp';

export default function Error() {
  return (
    <NextApp>
      <ErrorPage />
    </NextApp>
  );
}
