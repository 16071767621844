import { Button as AntButton, ButtonProps } from 'antd';

export const Button = ({
  lightblue,
  darkText,
  className,
  secondary,
  ...props
}: ButtonProps & {
  lightblue?: boolean;
  darkText?: boolean;
  secondary?: boolean;
}) => {
  return (
    <AntButton
      type={secondary ? 'default' : 'primary'}
      shape="round"
      size="large"
      {...props}
      className={`capitalize  
      ${darkText ? 'dark-text-btn' : ''}
      ${lightblue ? 'light-blue-btn' : ''}
      ${secondary ? 'btn-secondary border-primary text-primary' : ''}
      ${className ?? ''}`}
    />
  );
};
